import React from 'react'
import { PageProps, graphql } from 'gatsby'
import { Trans, useTranslation } from 'react-i18next'
import { MapCareerSupportQuery } from '@graphql-types'
import { css } from '@emotion/react'
import MAPLayout from './_layout'
import { GatsbyImage } from 'gatsby-plugin-image'
import ReactMarkdown from 'react-markdown'
import { minWidth, responsiveColumn } from 'src/themes'
import { ScrollAnimation } from '@components/ScrollAnimation'

const MAPCareerSupportPage = (props: PageProps<MapCareerSupportQuery>) => {
	const { t } = useTranslation()
	const course = props.data.course!
	return (
		<MAPLayout course={course}>
			<h2>{t('courses:common.career_support')}</h2>
			<p>{t('courses:map.career_support.description')}</p>

			<h3>{t('courses:map.developer_showcase.title')}</h3>
			<p>{t('courses:map.developer_showcase.description')}</p>
			<ScrollAnimation animateIn={'fadeInRight'}>
				<div
					css={css`
						display: flex;

						> div {
							flex: 1;
						}
					`}>
					{props.data.showcases.edges.map((showcase) => (
						<div>
							<GatsbyImage
								image={
									showcase.node.childImageSharp
										?.gatsbyImageData
								}
								alt={t('courses:map.developer_showcase.photo')}
							/>
						</div>
					))}
				</div>
			</ScrollAnimation>

			<h3>{t('courses:map.career_support_services')}</h3>

			<div>
				{props.data.careers.nodes.map((career, i) => (
					<div
						css={css`
							display: flex;
							gap: 2rem;
							margin-bottom: 4rem;

							${responsiveColumn};

							> div:first-of-type {
								/* width: 400px; */
								height: 400px;
							}

							> div:nth-child(2) {
								flex: 1;
							}

							${minWidth[2]} {
								&:nth-child(odd) {
									> div:first-of-type {
										order: 1;
									}
								}
							}
						`}
						key={i}>
						<ScrollAnimation
							animateIn={
								i % 2 === 0 ? 'fadeInLeft' : 'fadeInRight'
							}>
							<GatsbyImage
								image={
									career.imageSharp?.childImageSharp
										?.gatsbyImageData
								}
								alt={career.title!}
							/>
						</ScrollAnimation>
						<div>
							<h3>{career.title}</h3>
							<ReactMarkdown children={career.content!} />
						</div>
					</div>
				))}
			</div>
		</MAPLayout>
	)
}

export default MAPCareerSupportPage

export const query = graphql`
	query MapCareerSupport($language: String!) {
		...TranslationFragment
		...MAPCoursePageFragment
		showcases: allFile(
			filter: { relativePath: { regex: "/map-showcase/" } }
		) {
			edges {
				node {
					childImageSharp {
						gatsbyImageData(width: 400, placeholder: BLURRED)
					}
				}
			}
		}
		careers: allCockpitCareerSupportCollection(
			filter: { _lang: { eq: $language } }
		) {
			nodes {
				title
				content
				image {
					path
				}
				imageSharp {
					childImageSharp {
						gatsbyImageData(
							transformOptions: { fit: CONTAIN }
							backgroundColor: "transparent"
							height: 400
							width: 400
							placeholder: BLURRED
						)
					}
				}
			}
		}
	}
`
